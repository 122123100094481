<template>
  <form @submit.prevent="registration" class="entry-form__list">
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.name.$model"
        :class="{
          error: $v.name.$error || sendError,
          valid: name && !$v.name.$error,
        }"
        :placeholder="$t('auth.name')"
        class="input-entry"
        required
        type="text"
      />
      <div v-if="$v.name.$error" class="error-list__item">
        {{ $t("required_field") }}
      </div>
    </div>
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.email.$model"
        :class="{
          error: $v.email.$error || sendError,
          valid: email && !$v.email.$error,
        }"
        class="input-entry"
        placeholder="E-mail"
        required
        type="email"
      />
      <div v-if="$v.email.$error" class="error-list__item">
        {{ $t("auth.wrong_registration_email") }}
      </div>
    </div>
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.password.$model"
        :class="{
          error: $v.password.$error || sendError,
          valid: password && !$v.email.$error,
        }"
        :placeholder="$t('auth.password')"
        :type="inputType"
        autocomplete="off"
        class="input-entry password-field"
        required
      />
      <div v-if="$v.password.$error" class="error-list__item">
        {{ $t("min_len_pass", { number: "3" }) }}
      </div>
      <div class="switch-password">
        <button class="btn btn-empty" @click="toggleType(false)">
          <svg
            v-if="inputType === 'password'"
            v-svg
            role="presentation"
            size="0 0 18 16"
            symbol="view-1"
          ></svg>
          <svg
            v-else
            v-svg
            role="presentation"
            size="0 0 18 16"
            symbol="view-2"
          ></svg>
        </button>
      </div>
    </div>
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.repeatPassword.$model"
        :class="{
          error: $v.repeatPassword.$error || sendError,
          valid: repeatPassword && !$v.email.$error,
        }"
        :placeholder="$t('auth.password_confirm')"
        :type="inputTypeConfirm"
        autocomplete="off"
        class="input-entry password-field"
        required
      />
      <div v-if="$v.repeatPassword.$error" class="error-list__item">
        {{ $t("auth.password_confirm_wrong") }}
      </div>
      <div class="switch-password">
        <button class="btn btn-empty" @click="toggleType(true)">
          <svg
            v-if="inputTypeConfirm === 'password'"
            v-svg
            role="presentation"
            size="0 0 18 16"
            symbol="view-1"
          ></svg>
          <svg
            v-else
            v-svg
            role="presentation"
            size="0 0 18 16"
            symbol="view-2"
          ></svg>
        </button>
      </div>
    </div>
    <div class="entry-form__item input-template">
      <custom-multiselect
        v-model="currentMedia"
        :list="allMedia"
        :allowEmpty="false"
        class="select-block"
      >
        <template v-slot:block="props">
          <span
            class="btn-img"
            :class="currentMedia.value === 'telegram' ? 'telegram' : 'skype'"
          >
            <svg
              class="select-svg"
              v-svg
              role="presentation"
              size="0 0 20 20"
              :symbol="
                currentMedia.value === 'telegram' ? 'telegram2' : 'skype'
              "
            ></svg>
            {{ props.value.name }}
          </span>
        </template>
        <template v-slot:list="props">
          <span>
            {{ props.value.name }}
          </span>
        </template>
      </custom-multiselect>
      <input
        class="select-input"
        required
        type="text"
        v-model="mediaValue"
        :placeholder="`@UserName`"
      />
    </div>
    <vue-recaptcha
      class="py-2 d-flex justify-content-center w-100"
      sitekey="6LdWqHkUAAAAABxWRXeYYXWW6F2F3LswjImcYoWW"
      size="normal"
      theme="light"
      tabindex="0"
      :loadRecaptchaScript="true"
      @verify="recaptchaVerified"
      @expire="recaptchaExpired"
      @fail="recaptchaFailed"
      ref="vueRecaptcha"
    />
    <div class="entry-form__item _submit">
      <button class="btn _w100" type="submit">
        {{ $t("auth.sign_up") }}
      </button>
    </div>
    <div class="entry-form__item _privacy mb-0">
      <router-link to="/reset-password">{{
        $t("auth.forgot_password")
      }}</router-link>
    </div>
    <alert v-if="$alert.title" />
  </form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters, mapActions } from "vuex";
import { VueRecaptcha } from "vue-recaptcha";
import {
  email,
  maxLength,
  minLength,
  required,
  sameAs,
} from "vuelidate/lib/validators";
import AuthService from "@/api/serviceAuth";

export default {
  name: "Registration",
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      inputType: "password",
      inputTypeConfirm: "password",
      email: "",
      name: "",

      password: "",
      repeatPassword: "",
      recaptcha_response: "",
      sendError: false,
      utm_source: "",

      currentMedia: { value: "telegram", name: "Telegram" },
      allMedia: [
        { value: "telegram", name: "Telegram" },
        { value: "skype", name: "Skype" },
      ],
      mediaValue: "",
    };
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(32),
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
    mediaValue: {
      required,
    },
  },
  watch: {
    currentMedia: {
      handler() {
        this.mediaValue = "";
      },
    },
  },
  created() {
    let pid = localStorage.getItem("userPid");
    let utmParams = localStorage.getItem("utm_params");
    if (pid) this.utm_source = pid;
    if (utmParams) this.utm_params = utmParams;

    if (this.$route.query) {
      for (const key in this.$route.query) {
        if (key.startsWith("utm")) {
          this.utm_params = this.$route.fullPath.match(/\butm[^&]*/g).join("&");
          localStorage.setItem("utm_params", this.utm_params);
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
    }),
    recaptchaVerified(response) {
      this.recaptcha_response = response;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.$refs.vueRecaptcha.reset();
    },
    async registration() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("fields_invalid"),
        });
        return;
      }

      if (!this.recaptcha_response) {
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("toast_error_captcha"),
        });
        return;
      }
      const params = {
        name: this.name,
        email: this.email,
        password: this.password,
        recaptcha_response: this.recaptcha_response,
        telegram: this.currentMedia.value === "telegram" ? this.mediaValue : "",
        skype: this.currentMedia.value === "skype" ? this.mediaValue : "",
        referral_id: this.referralId ? this.referralId : 0,
        utm_source: this.utm_source,
        utm_params: this.utm_params,
      };
      AuthService.registration(params).then(
        (resp) => {
          if (resp.data.token && resp.data.refresh_token) {
            this.$gtm.trackEvent({ event: "sign_up" });

            localStorage.setItem("authToken", resp.data.token);
            localStorage.setItem("refreshToken", resp.data.refresh_token);
            this.$router.push("/dashboard");
            window.location.reload();
            this.setUser();
          } else {
            this.$alert({
              title: this.$t("sent_error"),
              text: resp.message,
            });
            this.recaptchaFailed();
          }
        },
        (error) => {
          console.log("error ======= ", error);
        }
      );
    },
    toggleType(el) {
      const element = el ? "inputTypeConfirm" : "inputType";
      this[element] === "password"
        ? (this[element] = "text")
        : (this[element] = "password");
    },
  },
  computed: {
    ...mapGetters({
      referralId: "user/getReferralId",
    }),
  },
};
</script>

<style lang="scss" scoped>
.switch-password .btn.btn-empty {
  padding: 0;
}

.btn-img {
  display: flex;
  min-width: max-content;
}

.input-template {
  width: 100%;
  background: #ffffff;
  border-radius: 50px;
  padding: 7px 9px;
  display: flex;
  align-items: center;
  gap: 4px;

  .select-block {
    background: #f5f5f5;
    border-radius: 20px;
    &::v-deep .multiselect__content-wrapper {
      top: 0px;
      border: 1px solid #bcbcbc;
      border-width: 1px;
      border-radius: 10px;
    }
    &::v-deep .multiselect__content {
      border-color: #bcbcbc;
      border: none;
    }
  }

  .select-input {
    width: 100%;
    border-radius: 20px;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    box-shadow: none !important;
  }

  .select-svg {
    fill: #747b87;
    margin-right: 8px;
  }

  &::v-deep {
    .multiselect__tags {
      padding: 0px 40px 0 8px;
      border-radius: 20px !important;
      box-shadow: none !important;
    }
    .multiselect__single {
      color: #747b87;
      font-size: 13px;
      font-weight: 300;
    }
  }
}
</style>